import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import InlineLink from "../../../components/Chronology/Modules/InlineLink";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDailyHighlight from "../../../components/Chronology/Modules/ModDailyHighlight";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModLethalityEu from "../../../components/Chronology/Modules/ModLethalityEu";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";
import ModFootnote from "../../../components/Chronology/Modules/ModFootnote";

export const frontmatter = {
  title: "Día 84",
  week: "Semana 12",
  day: "05",
  month: "Jun",
  monthNumber: "06",
  date: "2020-06-05",
  path: "/cronologia/semana-12#dia-05-jun/",
};

const Day84 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModFootnote>
          El sistema de seguimiento establecido por el Ministerio de Sanidad ha
          detectado esta semana 57.372 casos sospechosos de COVID-19. Asimismo,
          un 8% de las personas que han estado en contacto estrecho con casos
          positivos confirmados, ha desarrollado síntomas.
        </ModFootnote>
        <ModImage
          src="/images/svg/05_jun_mapaprovincial-anticuerpos.svg"
          alt="Mapa provincial anticuerpor covid19"
        />

        <ModDottedLine />
        <ModDataEu fecha={frontmatter.date} />
        <ModLethalityEu fecha={frontmatter.date} numPaises={8} />
      </ContentLeft>

      <ContentRight>
        <ModDailyHighlight>
          Décimo día del periodo de luto oficial
        </ModDailyHighlight>
        <ModImage src="/images/svg/luto-minuto-silencio.svg" alt="luto" />

        <ModText>
          En los últimos siete días se han diagnosticado 2,177 casos mediante
          pruebas PCR. En ese mismo periodo, 162 personas han requerido
          hospitalización (9,3% de los diagnósticos), de los que 14 han sido
          ingresos en la UCI, y se han producido 52 fallecimientos.
        </ModText>
        <ModText>
          La segunda ronda del estudio ENECovid19, sobre la inmunidad de la
          población española frente a COVID-19, la sitúa en valores muy
          similares a los de hace tres semanas, con una tasa del 5,21% (2,4
          mill.).
        </ModText>
        <ModText>
          El Ministerio de Industria, Comercio y Turismo ha publicado en su web
          siete nuevas guías con medidas higiénico-sanitarias para la reapertura
          segura del sector turístico. Se trata de las correspondientes a:
        </ModText>
        <ModDotList>Espacios naturales protegidos</ModDotList>
        <ModDotList>
          Instalaciones náutico-deportivas y actividades náuticas
        </ModDotList>
        <ModDotList>Ocio nocturno</ModDotList>
        <ModDotList>Parques de atracciones y de ocio</ModDotList>
        <ModDotList>
          Transporte turístico (empresas de autocares turísticos, rent-a-car,
          transporte por cable y transporte turístico acuático)
        </ModDotList>
        <ModDotList>
          Visitas a bodegas y otras instalaciones industriales
        </ModDotList>
        <ModDotList>Lugares culturales, históricos y naturales</ModDotList>
        <ModDotList>Espacios públicos singulares</ModDotList>

        <ModText>
          La Seguridad Social ha habilitado un teléfono de contacto gratuito
          para consultas sobre el Ingreso Mínimo Vital:
        </ModText>
        <ModImage
          src="/images/svg/05_jun_telefonos.svg"
          alt="telefonics seguridad social atencion renta covid19"
        />

        <ModText>
          Los Ministerio de Educación, Derechos Sociales y Alto Comisionado han
          elaborado la guía ‘
          <InlineLink link="https://www.comisionadopobrezainfantil.gob.es/sites/default/files/Los%20retos%20de%20la%20infancia%20en%20un%20verano%20excepcional.pdf">
            Los Retos de la Infancia en un verano excepcional
          </InlineLink>
          ’ con el objetivo de adaptar los programas de ocio educativo para la
          infancia a las nuevas circunstancias, sobre todo para los más
          vulnerables.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day84;
